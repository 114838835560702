@import "modules/responsive-type.scss";
@import url('https://fonts.googleapis.com/css?family=Krona+One|Montserrat');
$primary: #682c76;
/* MAIN COLOR */

$secondary: #74a69e;
/* SECONDARY COLOR */

$blk: #000;
/* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */

$wht: #fafafa;
$border-radius: 0px;
/* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */

$footerLinks: #fafafa;
// Set your maximum and minimum screen sizes.
$min_width: 320px;
$max_width: 1920px;
// Font sizes will range between the $min_font and $max_font.
$min_font: 15px;
$max_font: 22px;
// Change these values to change the "scale" between different headers
// (h1,h2,h3, etc.). Larger numbers = larger font-sizes. 
$mod_1: 1.2; // mobile
$mod_2: 1.5; // desktop
html,
body {
    height: 100%;
    font-family: 'Montserrat', sans-serif;
}

html {
    @include fluid-type($min_width,
    $max_width,
    $min_font,
    $max_font);
}

p {
    font-size: 1rem;
}

h1 {
    font-size: $mod_1*$mod_1*$mod_1*$mod_1 *1rem;
    @include fluid-type($min_width,
    $max_width,
    $mod_1*$mod_1*$mod_1 *$min_font,
    $mod_2*$mod_2*$mod_2 *$min_font);
}

h2 {
    font-size: $mod_1*$mod_1*$mod_1 *1rem;
    @include fluid-type($min_width,
    $max_width,
    $mod_1*$mod_1*$mod_1 *$min_font,
    $mod_2*$mod_2*$mod_2 *$min_font);
}

h3 {
    font-size: $mod_1*$mod_1 *1rem;
    @include fluid-type($min_width,
    $max_width,
    $mod_1*$mod_1 *$min_font,
    $mod_2*$mod_2 *$min_font);
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.flash {
    display: none;
}

q,
blockquote {
    quotes: "“" "”";
}

.navbarFixed {
    position: fixed;
    background-color: #fff;
    width: 100%;
    top: 0;
    z-index: 999;
}

/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

nav.navbar {
    z-index: 1000;
    border: none;
    border-radius: $border-radius;
    .navbar-nav {
        >li>a {
            background: $primary;
            @include fluid-type($min_width,
            $max_width,
            12px,
            15px);
            text-align: center;
            margin-top: 26px;
            display: flex;
            align-items: center;
            margin-left: 5px;
            border-radius: 0px;
            color: $wht;
            text-transform: uppercase;
            transition: 0.5s all ease;
            @media (max-width: 767px) {
                background: transparent;
                color: $blk;
                margin-top: 0;
                padding: 6px 0px;
                display: inline-block;
            }
            &:focus,
            &:active {
                background: $primary;
                color: $wht;
                outline: 0;
            }
            &:hover {
                background: $secondary;
                color: $wht;
                @media (max-width: 1024px) {
                    background: transparent;
                    color: $blk;
                    outline: 0;
                }
            }
        }
    }
}

.navbar-right {
    margin-top: 0px;
}

.navbar-collapse {
    padding-left: 0;
}

.navbar-toggle {
    margin: 20px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}

/** LOGIN FORM **/

@mixin btn {
    border: none;
    background: $primary;
    color: $wht;
    padding: 1em 2em;
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    margin: 0.5em 0em;
    transition: 0.7s ease all;
    &:hover {
        background: lighten($primary, 10%);
        color: lighten($wht, 10%);
        text-decoration: none;
        @media (max-width: 1024px) {
            background: $secondary;
            color: $wht;
            box-shadow: 0px 0px 5px $blk;
        }
    }
}

.btn-default {
    @include btn;
}

.modal-dialog {
    max-width: 300px;
    text-align: center;
    margin: 6em auto;
    .close {
        display: none;
    }
    .modal-content {
        color: $primary;
        h2 {
            text-align: center;
        }
    }
    input {
        border: 1px solid #777;
        text-align: center;
    }
    input#username,
    input#password {
        @media (max-width: 1024px) {
            font-size: 16px;
        }
    }
    button {
        background: transparent;
        color: $wht;
        display: block;
        border: none;
        padding: 1em 2em;
        margin: 0 auto;
        font-size: 18px;
        &:hover {
            background: transparent;
            color: #fff;
            box-shadow: none;
            text-shadow: 0px 0px 3px $blk;
            border: none;
        }
    }
    input[type="submit"] {
        @include btn;
        display: block;
        width: 75%;
        font-size: 18px;
        margin: 0 auto;
    }
}

.modal-header,
.modal-footer {
    background: $primary;
    color: $wht;
}

.modal-open {
    overflow: auto;
    padding-right: 0px !important;
}

input#username {
    margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
    padding: 50px 0px 20px;
    background: $primary;
    color: $footerLinks;
    font-size: 15px;
    a {
        color: $footerLinks;
        white-space: nowrap;
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
        }
        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }
    p {
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

input#username,
input#password {
    width: 100%;
    @media (max-width: 1024px) {
        font-size: 16px;
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

a,
a:hover,
a:focus,
a:active {
    outline: 0 !important;
}

.logo {
    max-width: 320px;
    padding: 0.5em;
}

@media(max-width: 991px) {
    .logo {
        max-width: 285px;
        margin-top: 10px;
    }
    .navbar-header a {
        float: left;
    }
}

@media(max-width: 767px) {
    .logo {
        max-width: 245px;
    }
    .navbar-header a {
        float: left;
    }
    .navbar-toggle {
        margin-top: 30px;
    }
}

@media (max-width: 600px) {
    .logo {
        max-width: 225px;
        margin-top: 0;
    }
    .navbar-toggle {
        margin-top: 20px;
    }
}

.top-pad {
    padding: 3em;
    background: white;
    @media (max-width: 990px) {
        padding: 1em;
    }
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 1024px) {
        display: block;
        /* may need to be inline-block */
    }
}

.terms,
.privacy {
    font-size: 1rem;
    a {
        color: $primary;
    }
    & ol {
        li {
            font-size: 1rem;
        }
        & ol li {
            font-size: 0.85rem;
        }
    }
}

.header {
    background: url('../img/img1.jpg');
    background-size: cover;
    background-position: center center;
    padding: 100px 0;
    @media (max-width: 550px) {
        padding: 100px 0;
    }
}

.headline {
    font-weight: 700;
    text-align: center;
    color: $primary;
    @include fluid-type($min_width,
    $max_width,
    60px,
    80px);
    text-shadow: 2px 2px 1px $primary;
    @media (max-width: 767px) {
        @include fluid-type($min_width, $max_width, 40px, 80px);
    }
}

.topline {
    text-align: center;
    @include fluid-type($min_width,
    $max_width,
    16px,
    30px);
    margin-bottom: -30px;
    letter-spacing: 23px;
    color: $wht;
    text-transform: uppercase;
    @media (max-width: 550px) {
        letter-spacing: 10px;
    }
}

.push {
    margin-top: 400px;
    @media (max-width: 991px) {
        margin-top: 200px;
    }
    @media (max-width: 767px) {
        margin-top: 100px;
    }
    @media (max-width: 550px) {
        margin-top: 0px;
    }
}

.box {
    background: rgba(182, 209, 187, .7);
    padding: 30px 0;
}

.center {
    display: block;
    margin: 0 auto;
    margin-bottom: 20px;
}

.sectTitle {
    text-align: center;
    color: $secondary;
}

i {
    font-size: 50px;
    color: $blk;
}

.featTitle {
    text-align: center;
    @include fluid-type($min_width,
    $max_width,
    16px,
    22px);
    font-weight: 700;
}

.featTxt {
    text-align: center;
    @include fluid-type($min_width,
    $max_width,
    12px,
    14px);
    font-weight: 400;
    margin-bottom: 30px;
}

.article-block {
    margin-top: 15px;
    text-align: center;
    width: 49.5%;
    margin-left: 5px;
    padding: 35px;
    border: 1px solid #ececec;
    border-radius: 0px;
    -webkit-transition-duration: .3s;
    p {
        padding: 5px 35px;
    }
    transition-duration: .3s;
    @media (max-width: 1200px) {
        width: 100%;
    }
    @media (max-width: 991px) {
        width: 96%;
        padding: 20px;
        p {
            padding: 5px 25px;
        }
    }
}

.pad {
    padding: 10em 0;
    @media(max-width: 767px) {
        padding: 5em 0;
    }
}

.pattern {
    background: url('../img/hypnotize.png') repeat
}

.article-block:hover {
    background: $secondary;
    color: $wht;
    @media (max-width: 991px) {
        background: transparent;
        color: $primary;
    }
}

.aboutTitle {
    @include fluid-type($min_width,
    $max_width,
    30px,
    45px);
}

.aboutTxt {
    @include fluid-type($min_width,
    $max_width,
    14px,
    16px);
}

.row {
    margin-right: 0;
    margin-left: 0;
}

.sectTop {
    text-align: center;
    margin-bottom: -20px;
}

.couple {
    background: url('../img/img2.jpg');
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    padding: 25em 0;
    @media (max-width: 767px) {
        background-attachment: scroll;
        padding: 5em 0;
    }
    @media (max-width: 1024px) {
        background-attachment: scroll;
        padding: 15em 0;
    }
}

.img-c {
    padding: 30px;
    border: 1px solid #ececec;
}

.last {
    border-top: 1px solid #ececec;
}

.couple2 {
    background: url('../img/img4.jpg');
    background-size: cover;
    background-attachment: fixed;
    padding: 25em 0;
    background-position: center center;
    @media (max-width: 767px) {
        background-attachment: scroll;
        padding: 5em 0;
    }
    @media (max-width: 1024px) {
        background-attachment: scroll;
        padding: 10em 0;
    }
}

.statement {
    text-align: center;
    color: $blk; // text-shadow: 2px 2px 1px $primary;
}
.article-block i {
    color: $primary
}
.col-md-4 i {
    color: $primary
}